import React from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { EpiContext } from '../Shared/Scripts/Store/EpiContext/Types';
import { FeilmeldingComponent } from '../Shared/Scripts/Components/FeilmeldingComponent';
import contentResolver, { resolveRawText } from '../Shared/Scripts/Utilities/EpiContentResolver';
import RadioButtonsStegContainer from '../Shared/Scripts/Components/RadioButtonsStegComponent';
import Valg from '../Shared/Scripts/Models/Valg';

import ATypeValg from './Utilities/A-TypeValg';
import BaSvarValg from './Utilities/Ba-SvarValg';
import BbSvarValg from './Utilities/Bb-SvarValg';
import BcSvarValg from './Utilities/Bc-SvarValg';
import ResultatComponent from './Components/ResultatKomponent';
import VeilederFristerDto from './Models/VeilederFristerDto';
import { VeilederFristerAppState } from './Store';
import * as Ids from './Models/Ids';
import * as ValgTypes from './Store/Valg/Types';
import { updateValg, updateVisning } from './Store/Valg/Actions';
import { ResultatValg } from './Store/Resultat/Types';
import SiteImproveHandler, { SiteImproveState, SiteImproveEvents, SiteImproveKategorier } from '../Shared/Scripts/Utilities/SiteImproveEventsHandler';

interface AppOwnProps {}

type AppProps = AppOwnProps & MapStateToProps & MapDispatchToProps;

const RadioButtonsStegComponent = RadioButtonsStegContainer<VeilederFristerDto>();

ReactModal.setAppElement(document.getElementById(Ids.AppContainerId) as HTMLElement);

export class App extends React.Component<AppProps> {
    constructor(props: AppProps) {
        super(props);
    }

    render(): JSX.Element {
        const { valg, resultat, dispatchUpdateValg, epiContext } = this.props;
        const { epiModel } = this.props.epiContext;

        if (epiContext.epiModelFeilet) {
            return <FeilmeldingComponent />;
        }

        function hentBreddeKlasse(): string {

            if(epiModel?.bredde === undefined || epiModel.bredde.value === "") {
                return "c-veileder c-veileder--mini";
            }

            //'c-veileder ' + epiModel.bredde !== undefined && epiModel.bredde.value !== "" ? epiModel.bredde.value : 'c-veileder--mini'
            return "c-veileder " + epiModel.bredde.value;
        }

        function registerSiteImproveEvents(): void {
            if(!SiteImproveState.Startet) {
                if(resultat.visBoksBa || resultat.visBoksBb || resultat.visBoksBc) {
                    //veileder påstartet
                    SiteImproveState.Startet = true;
                    SiteImproveHandler.registerSiteImproveEvent(SiteImproveKategorier.Veileder, SiteImproveEvents.VeilederStart, SiteImproveHandler.hentOverskrift());
                }
            }

            if(!SiteImproveState.Fullfort) {
                if(resultat.visBoksCa || resultat.visBoksCb || resultat.visBoksCc || resultat.visBoksCd || resultat.visBoksCe || resultat.visBoksCf || resultat.visBoksCg || resultat.visBoksCh || resultat.visBoksCi || resultat.visBoksCj || resultat.visBoksCk || resultat.visBoksCl || resultat.visBoksCm || resultat.visBoksCn) {
                    //veileder fullført
                    SiteImproveState.Fullfort = true;
                    SiteImproveHandler.registerSiteImproveEvent(SiteImproveKategorier.Veileder, SiteImproveEvents.VeilederFullfort, SiteImproveHandler.hentOverskrift());
                }
            }
        }

        if (epiModel != null) {
            return (
                <div id="veileder" className={hentBreddeKlasse()}>
                    {registerSiteImproveEvents()}
                    <RadioButtonsStegComponent
                        sporsmalId={Ids.BoksA}
                        tittel={contentResolver(epiContext, 'overskriftSteg1')}
                        forklaring={
                            <div>
                                <p>{contentResolver(epiContext, 'ingressSteg1')}</p>
                            </div>
                        }
                        sporsmal="ledetekstSteg1"
                        valg={ATypeValg.aValg}
                        onInputChange={(nyttValg: Valg<string>): void => {
                            dispatchUpdateValg({ aType: nyttValg });
                        }}
                        neverCollapse={true}
                        value={valg.aType}
                    />
                    {resultat.visBoksBa && (
                        <RadioButtonsStegComponent
                            sporsmalId={Ids.BoksBa}
                            tittel={contentResolver(epiContext, 'steg2StipendOgLanOverskrift')}
                            forklaring={
                                <div></div>
                            }
                            sporsmal="steg2StipendOgLanLedetekst"
                            valg={BaSvarValg.baStipendOgLanValg}
                            onInputChange={(nyttValg: Valg<string>): void => {
                                dispatchUpdateValg({ bSvar: nyttValg });
                            }}
                            neverCollapse={true}
                            value={valg.bSvar}
                        />
                    )}
                    {resultat.visBoksBb && (
                        <RadioButtonsStegComponent
                            sporsmalId={Ids.BoksBb}
                            tittel={contentResolver(epiContext, 'steg2BetaleGjeldOverskrift')}
                            forklaring={
                                <div></div>
                            }
                            sporsmal="steg2BetaleGjeldLedetekst"
                            valg={BbSvarValg.bbBetaleGjeldValg}
                            onInputChange={(nyttValg: Valg<string>): void => {
                                dispatchUpdateValg({ bSvar: nyttValg });
                            }}
                            neverCollapse={true}
                            value={valg.bSvar}
                        />
                    )}
                    {resultat.visBoksBc && (
                        <RadioButtonsStegComponent
                            sporsmalId={Ids.BoksBc}
                            tittel={contentResolver(epiContext, 'steg2SlettingGjeldOverskrift')}
                            forklaring={
                                <div></div>
                            }
                            sporsmal="steg2SlettingGjeldLedetekst"
                            valg={BcSvarValg.bcSlettingGjeldValg}
                            onInputChange={(nyttValg: Valg<string>): void => {
                                dispatchUpdateValg({ bSvar: nyttValg });
                            }}
                            neverCollapse={true}
                            value={valg.bSvar}
                        />
                    )}
                    {resultat.visBoksCa && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCa}
                            tittel = {contentResolver(epiContext, 'steg3ASvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3AStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3ASvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3AStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3AStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3ASvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3AStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3ACallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3ACallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3AStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3AStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3AFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3AModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3AModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3AModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3AModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCb && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCb}
                            tittel = {contentResolver(epiContext, 'steg3BSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3BStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3BSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3BStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3BStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3BSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3BStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3BCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3BCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3BStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3BStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3BFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3BModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3BModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3BModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3BModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCc && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCc}
                            tittel = {contentResolver(epiContext, 'steg3CSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3CStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3CSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3CStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3CStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3CSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3CStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3CCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3CCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3CStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3CStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3CFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3CModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3CModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3CModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3CModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCd && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCd}
                            tittel = {contentResolver(epiContext, 'steg3DSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3DStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3DSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3DStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3DStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3DSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3DStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3DCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3DCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3DStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3DStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3DFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3DModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3DModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3DModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3DModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCe && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCe}
                            tittel = {contentResolver(epiContext, 'steg3ESvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3EStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3ESvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3EStotteUnderTittel_1')}

                            
                            overskriftSvarfelt_2 = {contentResolver(epiContext, 'steg3EOverskriftSvarfelt_2')}
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3EStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3ESvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3EStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3ECallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3ECallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3EStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3EStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3EFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3EModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3EModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3EModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3EModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCf && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCf}
                            tittel = {contentResolver(epiContext, 'steg3FSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3FStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3FSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3FStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3FStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3FSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3FStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3FCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3FCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3FStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3FStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3FFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3FModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3FModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3FModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3FModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCg && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCg}
                            tittel = {contentResolver(epiContext, 'steg3GSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3GStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3GSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3GStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3GStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3GSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3GStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3GCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3GCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3GStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3GStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3GFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3GModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3GModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3GModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3GModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCh && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCh}
                            tittel = {contentResolver(epiContext, 'steg3HSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3HStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3HSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3HStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3HStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3HSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3HStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3HCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3HCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3HStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3HStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3HFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3HModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3HModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3HModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3HModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCi && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCi}
                            tittel = {contentResolver(epiContext, 'steg3ISvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3IStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3ISvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3IStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3IStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3ISvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3IStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3ICallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3ICallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3IStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3IStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3IFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3IModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3IModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3IModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3IModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCj && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCj}
                            tittel = {contentResolver(epiContext, 'steg3JSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3JStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3JSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3JStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3JStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3JSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3JStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3JCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3JCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3JStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3JStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3JFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3JModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3JModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3JModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3JModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCk && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCk}
                            tittel = {contentResolver(epiContext, 'steg3KSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3KStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3KSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3KStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3KStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3KSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3KStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3KCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3KCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3KStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3KStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3KFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3KModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3KModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3KModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3KModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCl && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCl}
                            tittel = {contentResolver(epiContext, 'steg3LSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3LStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3LSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3LStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3LStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3LSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3LStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3LCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3LCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3LStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3LStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3LFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3LModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3LModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3LModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3LModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCm && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCm}
                            tittel = {contentResolver(epiContext, 'steg3MSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3MStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3MSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3MStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3MStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3MSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3MStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3MCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3MCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3MStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3MStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3MFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3MModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3MModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3MModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3MModalLukkAriatekst')}
                        />
                    )}
                    {resultat.visBoksCn && (
                        <ResultatComponent
                            sporsmalId = {Ids.BoksCn}
                            tittel = {contentResolver(epiContext, 'steg3NSvarTittel')}

                            stotteSvarfelt_1 = {contentResolver(epiContext, 'steg3NStotteSvarfelt_1')}
                            svarfelt_1 = {contentResolver(epiContext, 'steg3NSvarfelt_1')}
                            stotteUnderTittel_1 = {contentResolver(epiContext, 'steg3NStotteUnderTittel_1')}

                            
                            stotteSvarfelt_2 = {contentResolver(epiContext, 'steg3NStotteSvarfelt_2')}
                            svarfelt_2 = {contentResolver(epiContext, 'steg3NSvarfelt_2')}
                            stotteUnderTittel_2 = {contentResolver(epiContext, 'steg3NStotteUnderTittel_2')}

                            callToActionLenke = {resolveRawText(epiContext, 'steg3NCallToActionLenke')}
                            callToActionLenkeTekst = {resolveRawText(epiContext, 'steg3NCallToActionLenkeTekst')}

                            standaloneLenke = {resolveRawText(epiContext, 'steg3NStandaloneLenke')}
                            standaloneLenkeTekst = {resolveRawText(epiContext, 'steg3NStandaloneLenkeTekst')}

                            fritekst = {contentResolver(epiContext, 'steg3NFritekst')}

                            modalTittel = {contentResolver(epiContext, 'steg3NModalTittel')}
                            modalTekst = {contentResolver(epiContext, 'steg3NModalTekst')}
                            modalLenkeTekst = {contentResolver(epiContext, 'steg3NModalLenketekst')}
                            modalAriaTekst = {resolveRawText(epiContext, 'steg3NModalLukkAriatekst')}
                        />
                    )}
                </div>
            );
        } else {
            return <div>Laster...</div>;
        }
    }
}

interface MapStateToProps {
    valg: ValgTypes.ValgState;
    epiContext: EpiContext<VeilederFristerDto>;
    resultat: ResultatValg;
}

interface MapDispatchToProps {
    dispatchUpdateValg: (newValg: Partial<ValgTypes.ValgState>) => void;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<VeilederFristerAppState, {}, AnyAction>): MapDispatchToProps => ({
    dispatchUpdateValg: (newValg: Partial<ValgTypes.ValgState>): void => {
        dispatch(updateValg(newValg));
        dispatch(updateVisning());
    },
});

const mapStateToProps = (state: VeilederFristerAppState): MapStateToProps => ({
    valg: state.valg,
    epiContext: state.epiContext,
    resultat: state.resultat,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
